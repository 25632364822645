@import "./../../index.scss";

.bubble-outer:hover {
  cursor: pointer;
}

.bubble-outer {
  .bubble-label {
    width: 15vw;
    text-align: center;
    font-size: 28px;
    font-family: "Avenir";
  }
  .bubble-container {
    width: 14vw;
    height: 14vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-blue-2;
      border-radius: 50%;
      width: 14vw;
      height: 14vw;
      transition: width 1s;
      // transition: height 1s, width 1s;
      transition: border-width .5s;
      img {
        width: 15vw;
      }
    }
    .img-container:hover {
      // width: 25px;
      // height: 25px;
      border: $primary-blue-1 5px solid;

    }
  }
}


@media (max-width: 768px) {
  .bubble-outer {
    .bubble-label {
      font-size: 18px;
    }
  }

}
