@import "./../../index.scss";

.icon-label {
    display: inline-block;
    text-align: center;
    align-items: center;
    .icon-image {
        border-radius: 50%;
        background-color: white;
        border-radius: 50%;
        display: inline-block;
        width:100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .icon-text {
        font-family: 'Avenir';
        font-size: 24px;
        width: 300px;
    }
}