@import "./../../index.scss";

.type {
    &-1 {
        background-image: url('./Rhombus.png');
        background-size: cover;
        width: 925px;
        height: 498px;
        display: flex;
        align-content: center;
        align-items: center;
        margin: 0 20px;
        div {
            padding: 25px;
        }
        .content-info {
            display: flex;
            flex-direction: column;
        }
    }
    &-2 {
        background-image: url('./Rhombus-1.png');
        background-size: cover;
        width: 1124px;
        height: 377px;
        display: flex;
        align-content: center;
        align-items: center;
        margin: 50px 20px;
        .image {
            padding-left: 100px;
        }
        .content-info {
            display: flex;
            padding: 25px;
            flex-direction: column;
            align-content: space-between;
            div {
                padding: 10px;
            }
        }
    }
    &-3 {
        background-image: url('./Rhombus-1.png');
        background-size: cover;
        width: 1124px;
        height: 377px;
        display: flex;
        align-content: center;
        align-items: center;
        margin: 50px 20px;
        background: #F0EFEE;
        border-radius: 40px;
        .image {
            padding-left: 20px;
            border-radius: 40px;
        }
        .content-info {
            display: flex;
            padding: 25px;
            flex-direction: column;
            align-content: space-between;
            div.h3 {
                font-size: 20px;
            }
            div {
                padding: 10px;
            }
        }
    }

    &-4 {
        background-image: url('./Rhombus-1.png');
        background-size: cover;
        width: 1124px;
        height: 377px;
        display: column;
        align-content: center;
        align-items: center;
        margin: 50px 20px;
        background: #F0EFEE;
        border-radius: 40px;
        .title{
            padding-top: 30px;
            padding-left: 30px;

        }
        .image {
            padding-left: 20px;

            
        }
    }
}

@media screen and (max-width: 768px) {


    .type-2 {
      height: auto;
      width: 100%;
      .image {
        display: none;
      }
      .content-info {
        padding: 60px;
      }
    }

    .type-3 {
        height: auto;
        width: 100%;
        .image {
          display: none;
        }
        .content-info {
          padding: 60px;
        }
      }
  }


  @media screen and (max-width: 768px) {
    .type-2 {
        height: auto;
        width: 100%;
        .image {
          display: none;
        }
        .content-info {
          padding: 20px;
          span {
            display: none;
          }
        }
      }


      .type-3 {
        height: auto;
        width: 100%;
        .image {
          display: none;
        }
        .content-info {
          padding: 30px;
          span{
            display: none;
          }
        }
      }


      .type-4 {
        padding: 10px;
        height: 150px;
        width: 100%;
      }
  }