@import "./../../../index.scss";

.page-header{
    width: '100%'; 
    height: '100%';
    flex-direction: 'column';
    align-content: 'center';
    gap: 30;
    display: 'inline-flex';
    .page-content{
      width: 1440px;
      height: 174px;
      padding: 30px 80px;
      justify-content: space-between;
      align-items: center;
      display: inline-flex;
    }
  }
  
  .home-container {
      .section {
        margin-bottom: 120px;
      }
      .header-container {
        background-image: url("./evtol_gif.gif");
        background-size: cover;
        padding: 150px;
        width: 1240px;
        height: 733px;
        .text-container {
          margin: 100px 0;
          display: flex;
          flex-direction: column;
          align-content: space-around;
        }
        .header-text {
          text-align: center;
          div {
            color: $primary-white-2;
          }
        }
        .button-container {
          display: flex;
          justify-content: space-around;
          align-items: center;
          align-content: space-around;
        }
      }
      .offerings-container {
        text-align: center;
        .main-bubble-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
        }
      }
      .enhancing {
        background-color: $primary-gray-2;
        border-radius: 30px;
        padding: 50px;
        display: flex;
        flex-flow: row nowrap;
        .button-container {
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          align-content: space-around;
        }
      }
      .how {
        .how-cards {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          overflow: scroll;
        }
      }
      .sam-app {
        background-color: $primary-blue-3;
        padding: 60px 80px;
        .sam-features {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-bottom: 80px;
        }
        .sam-examples {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
  
      .sam-app-white {
        padding: 60px 80px;
        .sam-features {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-bottom: 80px;
        }
        .sam-examples {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .sam-app-small-center {
        background-color: $primary-blue-3;
        border-radius: 40px;
        padding: 60px 80px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 70px;
        display: inline-flex;
        .title{
          align-self: stretch;
          text-align: center;
          color: #2D2D2D;
          font-size: 60px;
          font-family: Avenir;
          font-weight: 800;
          word-wrap: break-word;
        }
        .subtext{
          margin-top: 120px;
          align-self: stretch;
          text-align: center;
          color: #2D2D2D;
          font-size: 40px;
          font-family: Avenir;
          font-weight: 250;
          word-wrap: break-word;
        }
        .ending{
          width: 100%;
          height: 100%;
          padding-left:
          50px; padding-right: 50px; background: #0032B2; border-radius: 10px; justify-content: center; align-items: center; display: inline-flex
        }
        
      }
  
        .card_row{
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: inline-flex
        }
        .card{
          width: 340px;
          height: 240px;
          background: #DFE1E6;
          border-radius: 20px;
          .card_pic {
            height: 150px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            display: flex
          }
          .card_text{
            margin-left: 20px;
            padding-bottom: 20px;
            width: 300px;
            text-align: center;
            color: #2D2D2D;
            font-size: 24px;
            font-family: Avenir;
            font-weight: 350;
            word-wrap: break-word;
          }
        }
        
      }
  
    
    @media screen and (min-width: 769px) {
      .home-container {
        .header-container {
          padding: 150px;
        }
        .button-container {
          flex-direction: row;
        }
      }
    }
    
    @media screen and (max-width: 768px) {
      .home-container {
        .header-container {
          padding: 20px;
        }
        .button-container {
          flex-direction: column;
        }
      }
    }
    
    @media screen and (max-width: 1000px) {
      .home-container {
        .enhancing {
          flex-flow: row wrap;
        }
      }
    }
    