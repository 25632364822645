@import "./../../index.scss";

.home-container {
  .section {
    margin-bottom: 120px;
  }
  .header-container {
    background-image: url("./drone_footage.gif");
    background-size: cover;
    padding: 150px;
    .text-container {
      margin: 100px 0;
      display: flex;
      flex-direction: column;
      align-content: space-around;
    }
    .header-text {
      text-align: center;
      div {
        color: $primary-white-2;
      }
    }
    .button-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: space-around;
    }
  }
  .offerings-container {
    text-align: center;
    .main-bubble-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  .enhancing {
    background-color: $primary-gray-2;
    border-radius: 30px;
    padding: 50px;
    display: flex;
    flex-flow: row nowrap;
    .button-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      align-content: space-around;
    }
  }
  .how {
    .how-cards {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }
  .sam-app {
    background-color: $primary-blue-3;
    padding: 60px 80px;
    .sam-features {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .sam-examples {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (min-width: 769px) {
  .home-container {
    .header-container {
      padding: 150px;
    }
    .button-container {
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-container {
    .header-container {
      padding: 20px;
    }
    .button-container {
      flex-direction: column;
    }
  }

  .home-container .sam-app .sam-features {
    flex-direction: column;
    align-items: center;
}
}

@media screen and (max-width: 1000px) {
  .home-container {
    .enhancing {
      flex-flow: row wrap;
    }
  }
}
