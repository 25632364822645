@import "./../../index.scss";

.chip {
    font-family: 'Roboto', sans-serif;
    background-color: $primary-blue-3;
    color: $primary-blue-1;
    align-items: center;
    border-radius: 24px;
    display:inline-flex;
    padding: 0 10px;
    margin: 10px 5px 10px 0px;
    img {
        padding: 5px;
    }
}