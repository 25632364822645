@import "./../../index.scss";

button {
  font-family: "Avenir";
}

button:hover {
    cursor: pointer;
}

.primary,
.secondary {
  border-radius: 10px;
  border: 2px solid;
  padding: 0px 50px;
}

button.primary {
  background-color: $primary-blue-1;
  color: $primary-blue-3;
  border-color: $primary-blue-1;
}
button.secondary {
  opacity: 0.6;
  background: $primary-white-2;
  color: $primary-black;
  border-color: $primary-blue-1;
}
.tertiary,
.footer {
  background-color: transparent;
  border: 0;
}

.tertiary {
    border-bottom: 2px solid $primary-blue-1;
    color: $primary-blue-1;
}

.footer {
    color: $primary-blue-2;
    font-size: 24px;
}

@media screen and (min-width: 2561px) {
  button.large.primary,
  button.large.secondary {
    width: 332px;
    height: 80px;
    font-size: 28px;
  }
  button.small.primary,
  button.small.secondary {
    width: 309px;
    height: 70px;
    font-size: 26px;
  }
  .tertiary.large,
  .footer.large {
    font-size: 28px;
  }
  .tertiary.small,
  .footer.small {
    font-size: 26px;
  }
}

@media screen and (max-width: 2560px) and (min-width: 769px) {
  button.large.primary,
  button.large.secondary {
    width: 332px;
    height: 60px;
    font-size: 28px;
  }
  button.small.primary,
  button.small.secondary {
    width: 294px;
    height: 50px;
    font-size: 24px;
  }
  .tertiary.large,
  .footer.large {
    font-size: 28px;
  }
  .tertiary.small,
  .footer.small {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  button.large.primary,
  button.large.secondary,
  button.small.primary,
  button.small.secondary {
    width: 100%; /* Make the button full width */
    max-width: 120px; /* Ensure it doesn't exceed the container width */
        font-size: 18px; /* Adjust font size for better readability */
        height: auto; /* Allow height to adjust based on content */
        padding: 10px; /* Add padding for better appearance */
  }
  .tertiary.large,
  .footer.large,
  .tertiary.small,
  .footer.small {
    font-size: 18px;
  }
}
