@import "./../../index.scss";

.about {
    .section {
      margin-bottom: 120px;
    }
    .header-container {
      
      background-size: cover;
      padding: 30px;
    //   align-items: center; 
    //   display: inline-flex;
      .text-container {
        margin: 30px;
        display: flex;
        flex-direction: column;
        align-content: space-around;
      }
      .header-text {
        text-align: center;
        div {
          color: #2D2D2D;
          font-size: 56px;
          font-family: Avenir;
          font-weight: 800;
          word-wrap: break-word;
        }
      }
      .button-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;
      }
    }
    .offerings-container {
      text-align: center;
      .main-bubble-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
    .enhancing {
      background-color: $primary-gray-2;
      border-radius: 30px;
      padding: 50px;
      display: flex;
      flex-flow: row nowrap;
      .button-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;
      }
    }
    .how {
      .how-cards {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
      }
    }
    .sam-app {
      background-color: $primary-blue-3;
      padding: 60px 80px;
      .sam-features {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      .sam-examples {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }