@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./assets/fonts/AvenirLTStd-Roman.otf) format("opentype");
}

html {
  scroll-behavior: smooth;
}

$primary-black: #2D2D2D;
$primary-gray-2: #DFE1E6;
$primary-gray-1: #F0EFEE;
$primary-white-2: #FBFBFB;
$primary-white-1: #FFFFFF;
$primary-blue-3: #E5EDFF;
$primary-blue-2: #D6E2FB;
$primary-blue-1: #0032B2;
$primary-blue-1-transparent: #0032B20d;

$secondary-gray-3: #D1D0CD;
$secondary-cyan-2: #63E6E2;
$secondary-cyan-1: #70FFE0;
$secondary-blue-6: #EBF1FA;
$secondary-blue-5: #C9E6FC;
$secondary-blue-4: #0047FD;

a {
  all: unset;
}

h1,
h2,
h3,
h4 {
  font-family: "Avenir";
  // display: inline;
}

h5,
span {
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
}

@media screen and (min-width: 2561px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 64px;
  }
  h3 {
    font-size: 44px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 26px;
  }
  span.large {
    font-size: 24px;
  }

  span.small {
    font-size: 22px;
  }
}

@media screen and (max-width: 2560px) and (min-width: 769px) {
  h1 {
    font-size: 56px;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 18px;
  }
  span.large {
    font-size: 22px;
  }

  span.small {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 44px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  span.large {
    font-size: 14px;
  }

  span.small {
    font-size: 14px;
  }
}
