@import "./../../index.scss";


.contact-us {
    background: linear-gradient(
      to right,
      $primary-blue-2 0%,
      $primary-blue-2 50%,
      $primary-gray-2 50%,
      $primary-gray-2 100%
    );
    display: flex;
    flex-direction: row;
    border-radius: 40px;
    margin: 20px 50px 50px 50px;
    div.main {
      width: 50%;
      padding: 50px;
      display: flex;
      flex-direction: column;
    }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid $primary-blue-1;
    }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid $primary-blue-1;
    }
    .carousel > div {
      width: 300px;
      height: 300px;
    }
    .contact-form {
      h2 {
        color: $primary-blue-1;
      }
      div {
        padding: 10px 0;
        input {
          width: 100%;
          font-size: 22px;
          padding: 10px;
          font-family: "Avenir";
        }
      }
    }
    label {
      font-family: "Avenir";
      font-size: 22px;
      display: block;
    }
    input {
      all: unset;
      border-bottom: 1px solid black;
    }
  }


  @media screen and (max-width: 1000px) {
    
    .contact-us {
      flex-direction: column;
      background: $primary-gray-2;
      margin: 2px 5px  50px 5px;

      div.main {
        width: 80%;
        padding: 10px;
        flex-direction: column;
      }

      div.main:not(.contact-form) {
        display: none;
    }
    }

  }