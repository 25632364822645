@import "./../../index.scss";

.label {
    &-1 {
        color: $primary-black;
        font-size: 24px;
        font-family: 'Avenir';
        opacity: 0.5;
        padding: 40px;
    }
    &-2 {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        color: $primary-blue-1;
    }
    &-3 {
        font-family: "Roboto";
        font-size: 48px;
        color: $primary-gray-2;
    }
}

.label-1:hover {
    cursor: pointer;
}

.nav-link:hover {
    text-decoration: underline;
}

.subcontent {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.subnav:hover .subcontent{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 150px;
    a {
        padding: 10px;
    }
}


@media (max-width: 768px) {
    .label-1 {
        padding: 5px;
        padding-left: 40%;
    }

    
}