@import "./../../index.scss";

.nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-header .logo {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.nav-header .logo img {
    padding: 10px;
}

.nav-header .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-header .nav .header {
    padding: 10px 12px;
}

/* Styles for small screens */
@media (max-width: 768px) {
    .nav-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .nav-header .logo {
        justify-content: center;
        width: 100%;
        font-size: 18px; /* Adjust font size if needed */
    }

    .nav-header .nav {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }

    .nav-header .nav .header {
        width: 100%;
        text-align: left;
        padding: 8px 0; /* Adjust padding for better spacing */
    }

    .nav-header .nav button{
        display: none;
    }

}