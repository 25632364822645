@import "./../../index.scss";

.footer {
    background-color: $primary-black;
    border-radius: 40px;
    display: flex;
    align-items: center;
    color: $primary-blue-3;
    flex-direction: column;
    h2 {
        margin: 20px 0 0 0;
    }
    .column {
        display: flex;
        flex-direction: column;
    }
    .two-columns {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px;
    }
    .title {
        text-align: center;
    }
    h5 {
        font-weight: 600;
    }
    a {
        font-family: "Avenir";
        font-size: 16px;
    }
    a:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 610px) {
    .title{
        display: none;
    }
}